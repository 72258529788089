import * as React from 'react';
import AboutUs from '../components/AboutUs/AboutUs';
import AccessSectionRight from '../components/AccessSectionRight/AccessSectionRight';
import ArticleSection from '../components/ArticleSection/ArticleSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = ({ data, location }) => {
  return (
    <Layout>
      <AboutUs
        imageSrc="/corporate/img-business.webp"
        imageAlt="Example image"
        title="案内"
        subTitle="OUTLINE"
        sectionId="outline"
        items={[
          {
            title: '社名',
            description: 'シェスタ株式会社',
          },
          {
            title: '設立',
            description: '平成5年4月',
          },
          {
            title: '代表取締役',
            description: '皆川 幸司',
          },
          {
            title: '従業員数',
            description: '15名',
          },
          {
            title: '所在地',
            description:
              '〒111-0041 \n 東京都台東区元浅草4-10-6　AUSPICE元浅草9F',
          },
          {
            title: '事業内容',
            description:
              '・海外ブランド品の輸入・販売･卸し \n ・プライベートブランド商品の開発',
          },
          {
            title: '取引銀行',
            description:
              'みずほ銀行 / 千束町支店 \n 三菱東京UFJ銀行 / 浅草橋支店',
          },
        ]}
      />
      <ArticleSection
        title="企業理念"
        subTitle="MISSION"
        description={`
        わたくしたちは、「世界中の価値ある物を通じて、人々や社会に常に新しい価値を提供する」ことを
        ミッションとしています。

        目利きのスタッフが世界中を飛びまわり、満足度の高い“本物”のみを厳選し
        インターネットを通じてひとりでも多くのお客様の心を豊かにしてまいります。

        情報化社会の急速な発展がめまぐるしい昨今、世界中では日々新しい価値が生み出されております。
        その膨大な中から本当に価値あるものを長年培ってきた見極める「力」をもって、
        いち早く皆様へ発信できるよう一切の妥協をせず、日々実行し続けます。

        「新しい価値を不変の価値にかえていく企業」であり続けるビジョンのもと、
        社会に貢献する一員として、永続的に進化することを目指します。
        `}
        sectionId="section1"
        ctaLink="/init"
      />
      <AccessSectionRight
        mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25917.226534206493!2d139.787932!3d35.710148!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188f658e355e19%3A0x91e42a434792d698!2zQVVTUElDReWFg-a1heiNiQ!5e0!3m2!1sja!2sus!4v1710485702907!5m2!1sja!2sus"
        title="アクセス"
        subTitle="ACCESS"
        sectionId="access"
        description={
          '〒111-0041 東京都台東区元浅草4-10-6 AUSPICE元浅草9F \n (田原町駅 徒歩5分、TX浅草駅 徒歩10分、上野駅 徒歩10分)'
        }
        ctaLink="/contact"
      />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => (
  <Seo
    title="シェスタ株式会社(について"
    description="シェスタ株式会社(siesta)についてご紹介いたします。海外ブランド品の輸入・販売･卸し、プライベートブランド商品の開発。「世界中の価値ある物を通じて、人々や社会に常に新しい価値を提供する」ことを
  ミッションとしています。"
  />
);
